import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index.vue'
import channel from '@/views/channel.vue'
import detail from "@/views/detail";

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
    {
        path: '/',
        name: 'index',
        component: index,
        meta: {
            index: 1,      // 同样的标识
            keepAlive: true,
            content: '首页',
        }
    },
    {
        path: '/channel/:id',
        name: 'channel',
        component: channel,
        meta: {
            index: 2,      // 同样的标识
            keepAlive: true,
            content: '分类'
        }
    } ,{
        path: '/detail/:id',
        name: 'detail',
        component: detail,
        meta: {
            index: 3,      // 同样的标识
            keepAlive: true,
            content: '详情'
        }
    }
]

const router = new VueRouter({
    mode:'hash',
    routes
})

export default router
