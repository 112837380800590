<template>
    <div id="app">
        <!--头部 on-->
        <div class="is-header">
            <div class="m-head f-lg-ha" :style="topBannerNavBg">
                <div class="m-top visible-lg visible-md">
                    <div class="container f-clearfix">
                        <div class="u-topl f-fl">
                            欢迎访问<a href="/">{{ siteInfo.name }}</a>&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        <div class="u-topr f-fr">
                            <a @click="AddFavorite">加入收藏</a>
                        </div>
                    </div>
                </div>
                <div class="container f-clearfix">
                    <a href="/" class="m-logo f-db f-fl">
                        <img :src="siteInfo.logo" alt="" style="max-height: 90px;overflow: hidden">
                    </a>
                    <nav class="navbar navbar-default f-fl" role="navigation">
                        <div class="container-fluid">
                            <div class="navbar-header f-fl">
                                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#example-navbar-collapse">
                                    <span class="sr-only">切换导航</span>
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                </button>
                                <a class="navbar-brand" @click="handleNavigate({}, -2)">首页</a>
                            </div>

                            <div class="collapse navbar-collapse f-fl" id="example-navbar-collapse">
                                <ul class="nav navbar-nav">
                                    <li v-for="(item, index) in cateTree">
                                        <a @click="handleNavigate(item, -1)" target="_blank" v-if="item.childs === undefined || item.childs === null">{{ item.name }}</a>

                                        <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" v-if="item.childs !== undefined && item.childs.length !== 0">
                                            {{ item.name }} <span class="caret"></span>
                                        </a>
                                        <ul class="dropdown-menu" v-if="item.childs !== undefined && item.childs.length !== 0" style="background-color: #fff;text-align: center">
                                            <li>
                                                <a href="#" v-for="(child, childIndex) in item.childs" :key="childIndex" @click="handleNavigate(item, childIndex)">
                                                    {{ child.name }}
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        <!--头部 end-->

        <!--主体 on-->
        <router-view style="z-index: -1" v-if="flag"/>
        <!--主体 end-->

        <!--底部 on-->
        <div class="is-footer">
            <div class="container f-clearfix">
                <div class="m-footerl f-fl">
                    <p>
                        <a href="" target="_blank">首页</a>
                        <a href="" target="_blank"> | 关于公司</a>
                        <a href="" target="_blank"> | 人才招聘</a>
                    </p>
                    <p>Copyright 2024 {{ siteInfo.domain }} {{ siteInfo.name }} 版权所有 All Rights Reserved</p>
                    <p>公司地址：{{ siteInfo.address }} &nbsp; &nbsp; &nbsp;联系电话：{{ siteInfo.phone }}</p>
                    <p>Email：{{ siteInfo.email }} </p>
                    <p><a href="https://beian.miit.gov.cn/" target="_blank">{{ siteInfo.beian }}</a></p>
                </div>
            </div>
        </div>
        <!--底部 end-->
    </div>
</template>

<script>
import * as api from '@/api/api'

export default {
    name: 'App',
    components: {},
    data() {
        return {
            siteInfo: {},
            cateTree: [],
            flag: false,
            topBannerNavBg: {
                backgroundColor: ''
            },
            isSiteIndex: true, // 是否为首页
            topBannerNavBgColor_Trans: 'transparent',
            topBannerNavBgColor_Black: '#1e232f'
        }
    },
    mounted () {
        window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
    },
    created() {
        Promise.all([
            api.siteItem({siteId: this.$store.getters.siteId}),
            api.cateMenuTree({siteId: this.$store.getters.siteId})
        ]).then(res => {
            this.$router.push('/')
            res[0].banner = JSON.parse(res[0].banner)
            this.$store.commit('SET_SITEINFO', res[0])
            this.$store.commit('SET_CETELIST', res[1])
            sessionStorage.setItem("SITEINFO", JSON.stringify(res[0]))
            sessionStorage.setItem("CETELIST", JSON.stringify(res[1]))
            this.siteInfo = res[0]
            this.cateTree = res[1]
            this.flag = true
        })
    },
    methods: {
        // 滚动页面时触发导航变色
        handleScroll () {
            // 只有首页才滚动变化导航栏的背景色
            if (!this.isSiteIndex) {
                return;
            }
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            // 设置背景颜色的透明度
            if (scrollTop) {
                this.topBannerNavBg.backgroundColor = this.topBannerNavBgColor_Black // scrollTop + 多少根据自己的需求设置
            } else if (scrollTop === 0) {
                this.topBannerNavBg.backgroundColor = this.topBannerNavBgColor_Trans // 设置回到顶部时，背景颜色为透明
            }
        },
        // 滚动之前重置
        beforeDestroy () {
            window.removeEventListener('scroll', this.handleScroll)
        },
        handleNavigate(item, index) {
            if (index === -2) { // 首页
                this.isSiteIndex = true
                this.topBannerNavBg.backgroundColor = this.topBannerNavBgColor_Trans
                this.$router.push({
                    path: `/`,
                })
            } else {
                this.$store.commit('SET_ACTIVEID', item.id)
                this.$store.commit('SET_ACTIVEIDDEX', index)
                this.isSiteIndex = false
                this.topBannerNavBg.backgroundColor = this.topBannerNavBgColor_Black
                this.$router.push({
                    path: `/channel/${item.id}`,
                })
            }
        },
        //加入收藏夹，兼容多种浏览器
        AddFavorite() {
            let sUrl = window.location
            let sTitle = document.title
            try{window.external.addFavorite(sUrl,sTitle );}
            catch (e)
            {
                try{window.sidebar.addPanel(sTitle, sUrl, "");}
                catch (e){alert("加入收藏失败，请使用Ctrl+D进行添加");}
            }
        }
    }
}
</script>

<style>
@import "assets/bootstrap-3.3.7/css/bootstrap.min.css";
@import "assets/css/home.css";
@import "assets/css/page.css";
@import "assets/css/public.css";

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}
</style>
