<template>
    <div>
        <!--banner on-->
        <div>
            <el-carousel height="500px" arrow="always">
                <el-carousel-item v-for="(item, index) in banner" :key="index">
                    <img :src="item" style="width: 100%;height: 100%;">
                </el-carousel-item>
            </el-carousel>
        </div>
        <!--banner off-->

        <div class="is-main-index">
            <div class="container" v-if="flag">
                <div class="m-work f-mb20">
                    <div class="u-workc row f-clearfix">
                        <!--  首页推荐：小轮播图 -->
                        <div class="u-workl col-md-6">
                            <el-carousel height="380px" arrow="always" v-if="recommendArticle.length > 1">
                                <el-carousel-item :key="index" :label="index + 1" v-for="(item, index) in recommendArticle">
                                    <a target="_blank" class="u-workt f-db">
                                        {{item.title }}
                                    </a>
                                    <a>
                                        <img style="width: 100%;" :src="item.header" alt="" @click="navigateDetail(item)">
                                    </a>
                                </el-carousel-item>
                            </el-carousel>
                        </div>

                        <!--  首页推荐：文章列表 -->
                        <div class="u-workr f-md-ha col-md-6">
                            <div class="u-workrt">
                                <ul class="f-clearfix">
                                    <li v-for="(recommendItem, recommendIndex) in recommendCate" :key="recommendIndex" v-if=" recommendIndex <= 4" @click="handleRecommend(recommendIndex)">
                                        <a target="_blank" class="f-db" :class="recommendIndex == activeIndex ? 'active' : '' ">{{ recommendItem.name }}</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="u-workroutc">
                                <div class="u-workrc">
                                    <div class="u-workrclist f-clearfix" v-for="(articleItem, articleIndex) in articleList" v-if="articleIndex <= 4" @click="navigateDetail(articleItem)">
                                        <div class="u-time f-fl">
                                            <p>{{ formDay(articleItem.createTime) }}</p>
                                            <p>{{ formDate(articleItem.createTime) }}</p>
                                        </div>
                                        <div class="u-workrc1 f-fl">
                                            <a target="_blank">{{ articleItem.title }}</a>
                                            <p>{{ articleItem.description }}</p>
                                        </div>
                                        <div class="u-more f-fr">
                                            <a target="_blank" class="f-db"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--  行业方案 -->
                <div class="u-listhomet f-clearfix f-mb10">
                    <a class="u-listhometl f-db f-fl">{{ lastIndustrySolution.name }}</a>
                    <a class="u-listhometr f-db f-fr" @click="navigateChannel(lastIndustrySolution, -1)">MORE+</a>
                </div>
                <div class="u-jianshe">
                    <ul class="f-clearfix">
                        <li v-for="(articleItem, articleIndex) in lastIndustrySolution.articleList" :key="articleIndex" v-if="articleIndex < 18" @click="navigateDetail(articleItem)">
                            <a :title="articleItem.title" target="_blank" class="f-db">
                                <div><img :src="articleItem.header" alt=""></div>
                                <p>{{ articleItem.title }}</p>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <!--  业务流程 -->
            <div class="m-adv f-mb20 visible-lg visible-md c-shine f-pr f-oh" style="background: url(http://www.guangkecloud.cn/files/images/wide_center04.jpg) no-repeat; width: 1900px; height: 565px;">
<!--                <img src="http://www.guangkecloud.cn/files/images/wide_center04.jpg" alt="">-->
            </div>

        </div>
    </div>
</template>

<script>
import '../assets/js/qrcode.min.js'

import * as api from '@/api/api'

export default {
    name: "index",
    data() {
        return {
            companyInfo: {},
            articleList: [],
            recommendCate: [],
            industrySolution: [],
            recommendArticle: [],
            flag: false,
            activeIndex: 0,
            lastIndustrySolution: {}
        }
    },
    mounted () {
        this.$parent.$data.isSiteIndex = true
        this.$parent.$data.topBannerNavBg.backgroundColor = this.$parent.$data.topBannerNavBgColor_Trans
    },
    created() {
        let meta = document.createElement("meta")
        meta.id = 'index'
        meta.content = 'GuangkeCloud'

        document.title = '广科云创-高端网站建设/互联网营销/小程序开发/微信公众号开发'
        this.companyInfo = this.$store.state.siteInfo
        api.indexData({siteId: this.$store.getters.siteId}).then(res => {
            this.recommendArticle = res.recommendArticle
            this.industrySolution = res.industrySolution
            if((this.industrySolution.length % 2) !== 0) {
                this.lastIndustrySolution = this.industrySolution[this.industrySolution.length - 1]
            }

            this.recommendCate = res.recommendCate
            if(this.recommendCate.length === 0) {
                this.articleList = []
            } else {
                this.articleList = this.recommendCate[0].articleList
            }

            this.flag = true
        })
    },
    methods: {
        handleRecommend(recommendIndex) {
            this.activeIndex = recommendIndex
            this.articleList = this.recommendCate[recommendIndex].articleList
        },
        navigateDetail(item) {
            this.$router.push({
                path: `/detail/${item.id}`
            })
        },
        navigateChannel(item, index) {
            this.$store.commit('SET_ACTIVEIDDEX', index)
            this.$store.commit('SET_ACTIVEID', item.id)
            this.$router.push({
                path: `/channel/${item.id}`,
            })
        }
    },
    computed: {
        formDate() {    // JS 不同时间格式转换(ISO时间&时间戳转北京时间)
            return date => {
                return date.substr(0,10)
            }
        },
        formDay() {
            return day => {
                let dt = new Date(day)
                dt = dt.getDate()
                return dt < 10 ? '0' + dt : dt;
            }
        },
        banner() {
            return this.$store.getters.siteInfo.banner
        }
    }
}
</script>

<style scoped>

.active {
    color: #005B84;
}

</style>
